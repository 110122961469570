import Link from "next/link"
import React, {memo, useState} from "react"
import style from "styles/subscribe.module.css"
import useLocale from '../../../../hooks/useLocale'

const Subscribe = ({ subscribed }:any) => {
	const {subscribeSection} = useLocale()
	// @ts-ignore
	const [text, setText] = useState('')
	const [error, setError] = useState(false)
	const [errorText, setErrorText] = useState('')
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)

	console.log(subscribed)

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}

	return (
		<div className={style.subscribe}>
			<div className={style.container1}>
				<div className={style.wrapper}>
					
					
					<div >
						<p className={`${style.title} ${style.titlec}`}>{subscribeSection.title}</p>
						
						<p className={style.text}>{subscribeSection.definition1}</p>						
						<p className={style.text}>{subscribeSection.definition2}</p>
						<p className={style.text}>{subscribeSection.definition3}</p>
					</div>
					
					<div className={style.image}>
							<img alt="" className="-mt-[-20px] ml-2 md:-mt-[-10px] md:-ml-[-10px]  .d-none .d-sm-block" src="/image/aigaming.jpg"/>
					</div>								
					
				</div>
			</div>			
			<div className={style.container2}>
				<div className={style.wrapper}>
					<div className={style.image}>
						<img alt="" className="-mt-[-110px] ml-2 md:-mt-[-120px] md:-ml-[-50px]  .d-none .d-sm-block" src="/image/subscribe.svg"/>
					</div>

					<div>
						<p className={`${style.text} ${style.textc}`}>{subscribeSection.dontMiss}</p>
						<p className={`${style.title} ${style.titlec}`}>{subscribeSection.weAreGoing}</p>
						<p className={style.text}>{subscribeSection.beTheFirst}</p>

						{!success
							?
							<>
								<form method="post" accept-charset="UTF-8" action="https://www.aweber.com/scripts/addlead.pl" className="relative">
								<input type="hidden" name="meta_web_form_id" value="706322822" />
								<input type="hidden" name="meta_split_id" value="" />
								<input type="hidden" name="listname" value="awlist6377458" />
								<input type="hidden" name="redirect" value="http://metaisland.gg/subscribe" id="redirect_a20694267022238a40be883f2cf2d125" />
								<input type="hidden" name="meta_redirect_onlist" value="http://metaisland.gg/subscribed" />
								<input type="hidden" name="meta_adtracking" value="Blogform_Metaisland.gg" />
								<input type="hidden" name="meta_message" value="1" />

								<input type="hidden" name="meta_tooltip" value="" />
									<input placeholder={subscribeSection.placeholder} type="email"
												name="email"
												className={[style.input, error ? style.error : ''].join(" ")} value={text}
												onChange={(e) => setText(e.target.value)}/>
									<button disabled={loading} type="submit" className={style.button}>{subscribeSection.subscribe}</button>
								</form>
								
								{errorText && (
									<p className={style.errorText}>{errorText}</p>
								)}
							</>
							:
							<div className={style.success}>
								{subscribeSection.success}
							</div>
						}
						<br />
						<p className={`${style.text}`}>
							{subscribeSection.joinToFreeNft}
							<a
								target='_blank' href='https://discord.gg/qeZU4DqQFu'
								className={`${style.subscribe_linkItem}`}>&nbsp;{subscribeSection.groupName}&nbsp;
							</a>
						{subscribeSection.claimYourNft}
						<a
								target='_blank' href='https://discord.gg/qeZU4DqQFu'
								className={`${style.subscribe_linkItem}`}>&nbsp;{subscribeSection.freeNft}&nbsp;
						</a>
						{subscribeSection.claimNft}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default memo(Subscribe)
